import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LinksUteisEnum } from 'src/app/core/helpers/enums/links-uteis.enum';
import { BlurService } from 'src/app/core/services/blur.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { TermoAceiteLgpdComponent } from './termo-aceite-lgpd/termo-aceite-lgpd.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [trigger('toggle', [state('true', style({ opacity: 1 })), state('void', style({ opacity: 0 })), transition(':enter', animate('400ms ease-in-out')), transition(':leave', animate('200ms ease-in-out'))])]
})
export class HeaderComponent implements OnInit, OnChanges {
  @ViewChild('btnClose') btnClose: ElementRef;
  indexMenuAtivo: number = 0;
  subMenuAcitve: Boolean = false;
  menuPrincipal: Menu[] = [];
  menuAtivo: Menu;
  bodyEl = document.getElementsByTagName('body')[0];
  htmlEl = document.getElementsByTagName('html')[0];

  constructor(
    private modal: MatDialog,
    private _modalService: ModalService,
    private _blurService: BlurService,
    public router: Router
  ) {
    router.events.subscribe(() => {
      this.closeMenuAll();
    });
  }

  ngOnInit(): void {
    this.getMenuPrincipal();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }

  abrirModalAbraSuaConta() {
    this._blurService.setBlurBackground(true);
    this._modalService.abrirModalAcesseSuaConta();
  }

  abrirModalAceiteLGPD() {
    this._blurService.setBlurBackground(true);
    this.modal.open(TermoAceiteLgpdComponent, { disableClose: true });
  }

  abrirConta() {
    window.open(LinksUteisEnum.DAYCONNECT, '_blank');
  }

  abrirConsignado() {
    window.open(`${environment.urlDominioSite}credito-para-voce/credito-consignado/simulador-credito-consignado`, '_blank', 'noopener');
  }

  closeMenuAll() {
    this.exibirSubmenu(false);
    if (
      this.btnClose.nativeElement.attributes['aria-expanded'].value === 'true'
    ) {
      this.btnClose.nativeElement.click();
    }
  }

  setMenuAtivo(menu: Menu) {

    if(this.indexMenuAtivo !== menu.index && this.menuPrincipal[this.indexMenuAtivo].open ) {
      this.menuPrincipal[this.indexMenuAtivo].open = !this.menuPrincipal[this.indexMenuAtivo].open;
    }

    this.menuPrincipal[menu.index].open = !this.menuPrincipal[menu.index].open;
    this.exibirSubmenu(this.menuPrincipal[menu.index].open);
    this.indexMenuAtivo = this.menuPrincipal[menu.index].index;
    this.menuAtivo = this.menuPrincipal[menu.index];

  }

  exibirSubmenu(exibir: boolean) {
    this.subMenuAcitve = exibir;
    if (exibir) {
      this.addOverflowMenuFixPagina();
    } else {
      this.removeOverflowMenuFixPagina();
    }
  }

  addOverflowMenuFixPagina() {
    this.bodyEl.classList.add('overflow-menu-fix');
    this.htmlEl.classList.add('overflow-menu-fix');
  }

  removeOverflowMenuFixPagina() {
    this.bodyEl.classList.remove('overflow-menu-fix');
    this.htmlEl.classList.remove('overflow-menu-fix');
  }

  getMenuPrincipal() {
    this.menuPrincipal = [
      {
        titulo: 'Institucional',
        descricao:
          'Precisa de ajuda com algum produto ou possui dúvidas? Aqui é onde você pode encontrar todas suas respostas.',
        index: 0,
        open: false
      },
      {
        titulo: 'Crédito para Você',
        descricao:
          'Confira todas as soluções de crédito que o Daycoval tem para você',
        index: 1,
        open: false
      },
      {
        titulo: 'Crédito para sua Empresa',
        descricao:
          'Se você busca crédito ou empréstimo veja o que o Banco Daycoval pode oferecer para você!',
        index: 2,
        open: false
      },
      {
        titulo: 'Investimentos',
        descricao:
          'Simule e confira nosso portfólio com diversas opções de investimento para todos os perfis.',
        index: 3,
        open: false
      },
      {
        titulo: 'Câmbio',
        descricao:
          'Produtos e serviços especializados, desde seguro viagem até envio de dinheiro para o exterior.',
        index: 4,
        open: false
      },
      {
        titulo: 'Tesouraria',
        descricao: '',
        index: 5,
        open: false
      },
      {
        titulo: 'Serviços',
        descricao:
          'Pensando em praticidade e segurança, veja tudo que o Banco Daycoval pode oferecer para você.',
        index: 6,
        open: false
      },
      {
        titulo: 'Atendimento',
        descricao: '',
        link: '/atendimento',
        index: 7,
        open: false
      },
    ];
  }
}

interface Menu {
  titulo: string;
  descricao: string;
  link?: string;
  index: number;
  open?: boolean;

}
