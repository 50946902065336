<!--menu Desktop-->
<section class="menu-principal d-none d-lg-flex menu-generico
  justify-content-between" *ngIf="submenuAtivo">
  <div class="container align-items-start">
    <div class="row w-100" style="background: linear-gradient(to right,#00194d 0%,#00194d 5%,#fff 5%);">
      <div class="col-lg-3 d-none d-lg-block" id="menu-descricao">
        <div class="row ml-0">
          <h1>{{menuAtivo.titulo}} <span class="ponto-grande" id="azul"></span></h1>
        </div>
        <div class="row mt-3 mx-0">
          <p>
            {{menuAtivo.descricao}}
          </p>
        </div>
      </div>

      <div class="menu-links col-12 col-lg-9">
        <div class="close-icon">
          <img class="img-size" src="../../../assets/img/icons/icon-close.svg" alt="close-icon" (click)="fecharSubmenu()">
        </div>
        <div class="container" id="submenu-titulo">
          <div class="w-100 row no-gutters">
            <ng-container *ngIf="submenuAtivo.length <= quebraColuna">
              <div class="col-12 col-lg-6">
                <div class="center" *ngFor="let itemMenu of submenuAtivo">
                  <a *ngIf="!itemMenu.link?.EhLinkExterno" data-ehExterno="false" class="d-block center"
                    [routerLink]="itemMenu.link.Link" (click)="fecharSubmenu()">
                    <span class="d-inline-flex align-top img-fluid mr-2 icone">
                      <img class="mw-100" [src]="itemMenu.icone">
                    </span>
                    {{itemMenu.link.Descricao}}</a>
                  <a *ngIf="itemMenu.link?.EhLinkExterno" data-ehExterno="true" class="d-block center"
                    [href]="itemMenu.link.Link" target="_blank" (click)="fecharSubmenu()">
                    <span class="d-inline-flex align-top img-fluid mr-2 icone">
                      <img class="mw-100" [src]="itemMenu.icone">
                    </span>
                    {{itemMenu.link.Descricao}}</a>
                </div>

              </div>
            </ng-container>
            <ng-container *ngIf="submenuAtivo.length > quebraColuna">
              <div class="col-12 col-lg-6">
                <div class="center" *ngFor="let itemMenu of submenuAtivo | slice:0:quebraColuna">

                  <a *ngIf="!itemMenu.link.EhLinkExterno" class="d-block center" [routerLink]="itemMenu.link.Link"
                    (click)="fecharSubmenu()">
                    <span class="d-inline-flex align-top img-fluid mr-2 icone">
                      <img class="mw-100" [src]="itemMenu.icone">
                    </span>
                    {{itemMenu.link.Descricao}}</a>
                  <a *ngIf="itemMenu.link.EhLinkExterno" class="d-block center" [href]="itemMenu.link.Link"
                    target="_blank" (click)="fecharSubmenu()">
                    <span class="d-inline-flex align-top img-fluid mr-2 icone">
                      <img class="mw-100" [src]="itemMenu.icone">
                    </span>
                    {{itemMenu.link.Descricao}}
                  </a>
                </div>
                

              </div>
              <div class="col-12 col-lg-6">
                <div class="center" *ngFor="let itemMenu of submenuAtivo |
                  slice:quebraColuna">
                  <a *ngIf="!itemMenu.link.EhLinkExterno" class="d-block center" [routerLink]="itemMenu.link.Link"
                    (click)="fecharSubmenu()">
                    <span class="d-inline-flex align-top img-fluid mr-2 icone">
                      <img class="mw-100" [src]="itemMenu.icone">
                    </span>
                    {{itemMenu.link.Descricao}}</a>
                  <a *ngIf="itemMenu.link.EhLinkExterno" class="d-block center" [href]="itemMenu.link.Link"
                    target="_blank" (click)="fecharSubmenu()">
                    <span class="d-inline-flex align-top img-fluid mr-2 icone">
                      <img class="mw-100" [src]="itemMenu.icone">
                    </span>
                    {{itemMenu.link.Descricao}}</a>
                </div>
              </div>
            </ng-container>

          </div>
        </div>
      </div>
    </div>
  </div>
  <app-acesso-rapido-menu class="d-none d-lg-block mt-4" [indexMenuAtivo]="menuAtivo.index">
  </app-acesso-rapido-menu>

</section>


<!--menu Mobile-->
<section class="menu-principal d-flex d-lg-none menu-generico
  justify-content-between" *ngIf="submenuAtivo">
  <div class="container mw-100 align-items-start">
    <div class="row">
      <div class="menu-links col-12">
        <div class="container mw-100" id="submenu-titulo">
          <div class="row d-block d-lg-none bg-dark-grey center p-3 back-menu" (click)="fecharSubmenu()">
            <span class="material-icons mr-3">
              arrow_back
            </span>
            {{menuAtivo.titulo}}
          </div>
          <div class="row row-menus">
            <div class="col-12 col-lg-6 col-menus">
              <div class="center" *ngFor="let itemMenu of submenuAtivo">
                <div>
                  <a *ngIf="!itemMenu.link.EhLinkExterno" class="d-block center
                  my-0" [routerLink]="itemMenu.link.Link" (click)="fecharMenu()">
                    <span class="d-inline-flex align-top img-fluid mr-2 icone">
                      <img class="mw-100" [src]="itemMenu.icone">
                    </span>
                    {{itemMenu.link.Descricao}}
                  </a>
                  <a *ngIf="itemMenu.link.EhLinkExterno" class="d-block center
                  my-0" [href]="itemMenu.link.Link" target="_blank" (click)="fecharMenu()">
                    <span class="d-inline-flex align-top img-fluid mr-2 icone">
                      <img class="mw-100" [src]="itemMenu.icone">
                    </span>
                    {{itemMenu.link.Descricao}}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
