<section class="header">
  <div class="menu d-none d-lg-block">
    <nav class="navbar navbar-expand-lg navbar-dark area-menu-principal
      bg-white">
      <div class="container px-0 position-static">
        <div class="collapse navbar-collapse flex-wrap mw-100" id="day-navbar">
          <div class="row w-100 center pt-3 d-none">
            <div class="col-3">
              <a class="navbar-brand brand-logo d-block p-0" routerLink='/home'
                (click)="exibirSubmenu(false)">
                <img class="align-top img-fluid pl-0"
                  [src]="'assets/img/logo/logo-daycoval-dark.png'" alt="Logo Banco Daycoval">
              </a>

              <button aria-controls="day-navbar" aria-expanded="false"
                aria-label="Toggle navigation" id="btn-hb"
                class="navbar-toggler collapsed" data-target="#day-navbar"
                data-toggle="collapse" type="button">
                <span class="navbar-toggler-icon-hambuguer"><i
                    class="material-icons">view_headline</i></span>
                <span class="navbar-toggler-icon-close">
                  <i class="material-icons" [style.cursor]="'pointer'" [style.z-index]="'99999'">close</i></span>
                <span class="texto-menu">Menu</span>
              </button>
            </div>
            <div class="col-9">
              <div class="header-links d-flex justify-content-end w-100">
                <app-botoes-navegacao-menu></app-botoes-navegacao-menu>
                <app-icones-navegacao-menu class="center"></app-icones-navegacao-menu>
              </div>
            </div>
          </div>
          <div class="row w-100 position-static">
            <div class="col-12 position-static">
              <ul class="navbar-nav">
                <li class="nav-item" routerLinkActive *ngFor="let menu of
                  menuPrincipal">
                  <a *ngIf="!menu.link" class="nav-link dropdown-toggle" (click)="exibirSubmenu(false)"
                    (click)="setMenuAtivo(menu)"
                    [class.active]="indexMenuAtivo == menu.index &&
                    subMenuAcitve">
                    <p class="position-relative">{{menu.titulo}}
                      <i *ngIf="!menu.open" class="seta-down material-icons">expand_more</i>
                      <i *ngIf="menu.open" class="seta-down material-icons">expand_less</i>
                    </p>
                    <app-submenu [menuAtivo]="menuAtivo"
                      (closeSubmenu)="exibirSubmenu($event)"
                      data-menuAtivo="menu.index"
                      data-indexAtivo="indexMenuAtivo"
                      [@toggle]="menu.index == indexMenuAtivo && subMenuAcitve
                      && !menu.link"
                      *ngIf="menu.index == indexMenuAtivo && subMenuAcitve &&
                      !menu.link"></app-submenu>
                  </a>
                  <a *ngIf="menu.link" class="nav-link dropdown-toggle" (click)="exibirSubmenu(false)"
                    [routerLink]="menu.link" [class.active]="indexMenuAtivo ==
                    menu.index && subMenuAcitve">
                    <p class="position-relative">{{menu.titulo}}</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>

  <!--MOBILE-->

  <div class="menu menu-mobile d-block d-lg-none">
    <nav class="navbar navbar-expand-lg navbar-dark area-menu-principal
      bg-white">
      <div class="container mw-100 px-0 relative">
        <div class="row fst w-100 mx-0">
          <div class="col-2 col-sm-1 center px-2" id="hamburguer">
            <button aria-controls="day-navbar" aria-expanded="false"
              aria-label="Toggle navigation" id="btn-hb"
              class="navbar-toggler collapsed px-0"
              data-target=".multi-collapse" data-toggle="collapse" type="button"
              #btnClose (click)="exibirSubmenu(false)">
              <span class="navbar-toggler-icon-hambuguer">
                <i class="material-icons">view_headline</i>
              </span>
            </button>
          </div>
          <div class="col-6 col-sm-8 col-md-4 px-0 mx-0 center" id="logo">
            <a class="navbar-brand brand-logo d-block p-0 m-0" routerLink='/home'
              (click)="exibirSubmenu(false)">
              <img class="align-top img-fluid ml-1 pl-0"
                [src]="'assets/img/logo/logo-daycoval-dark.png'" alt="Logo Banco Daycoval">
            </a>
          </div>
          <app-botoes-navegacao-menu class="col-md-5 col-sm-1 d-md-block d-none center"></app-botoes-navegacao-menu>
          <app-icones-navegacao-menu class="col-sm-2 col-4 center"></app-icones-navegacao-menu>
        </div>

        <div class="row w-100 no-gutters">
          <div class="col-4 d-flex" style="border: solid 1px white">
            <button type="button" class="btn btn-primary w-100 rounded-0 justify-content-center d-flex"
              id="consignado-novo"
              (click)="abrirConsignado()">
              <span style="margin: auto;">CRÉDITO CONSIGNADO</span>
            </button>
          </div>
          <div class="col-4 d-flex" style="border: solid 1px white">
            <button type="button" class="btn btn-primary w-100 rounded-0"
              id="btn-abra-sua-conta"
              (click)="abrirModalAbraSuaConta()">
              ABRA SUA CONTA
            </button>
          </div>
          <div class="col-4" style="border: solid 1px white">
            <button class="btn btn-secondary w-100 p-0 m-0 rounded-0 h-100"
              id="btn-acesse-sua-conta"
              (click)="abrirConta()">
              <span class="user-icon mr-1"></span>
              ACESSAR CONTA
            </button>
          </div>
        </div>
      </div>

      <div class="collapse navbar-collapse multi-collapse" id="day-navbar"
        #navbar>
        <div class="container mw-100 px-0">
          <div class="row m-0 w-100">
            <ul class="navbar-nav col-12 px-0">
              <li class="nav-item" routerLinkActive *ngFor="let menu of
                menuPrincipal">
                <a *ngIf="!menu.link" class="nav-link dropdown-toggle" (click)="setMenuAtivo(menu)"
                  [class.active]="indexMenuAtivo == menu.index &&
                  subMenuAcitve">
                  <p class="position-relative">{{menu.titulo}}
                    <i class="seta-down material-icons">chevron_right</i>
                  </p>
                </a>
                <a *ngIf="menu.link" class="nav-link dropdown-toggle" (click)="closeMenuAll()"
                  [routerLink]="menu.link" [class.active]="indexMenuAtivo ==
                  menu.index && subMenuAcitve">
                  <p class="position-relative">{{menu.titulo}}</p>
                </a>
              </li>
            </ul>
            <app-acesso-rapido-menu class="d-block mt-4"
              [indexMenuAtivo]="indexMenuAtivo">
            </app-acesso-rapido-menu>
          </div>
        </div>
      </div>
    </nav>
  </div>

  <div class="submenu-container d-block d-lg-none" *ngIf="menuAtivo">
    <app-submenu [menuAtivo]="menuAtivo" (closeMenu)="closeMenuAll()"
      (closeSubmenu)="exibirSubmenu($event)"
      *ngIf="menuAtivo && subMenuAcitve && !menuAtivo.link"></app-submenu>
  </div>
</section>

<app-termo-aceite-lgpd></app-termo-aceite-lgpd>
