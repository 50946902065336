import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DayaneAssuntoRedirectHelper } from 'src/app/core/helpers/dayane-assunto-redirect.helper';
import { LinksUteisEnum } from 'src/app/core/helpers/enums/links-uteis.enum';
import { LojasHelper, RotaLojasEnum, urlAgencias } from 'src/app/core/helpers/lojas.helper';
import { Footer } from 'src/app/core/models/footer.model';
import { Link } from 'src/app/core/models/link.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  colunas: { menus: Footer[] }[] = [];
  @Input() currentPageBool: boolean;
  constructor(public router: Router) { }

  ngOnInit(): void {
    this.listarItensRodape();
  }

  listarItensRodape() {
    this.colunas = [
      {
        menus: [
          {
            Titulo: 'Institucional', Item: [
              new Link('Sobre nós', '/institucional/sobre-nos'),
              new Link('Sustentabilidade', '/sustentabilidade'),
              new Link('Trabalhe Conosco', '/institucional/trabalhe-conosco'),
              new Link('Notícias e Imprensa', '/institucional/noticias-e-imprensa'),
              new Link('Relações com Investidores', LinksUteisEnum.RI, true),
              new Link('Ouvidoria', '/institucional/ouvidoria'),
              new Link('Agências e Correspondentes', urlAgencias),
              new Link('Blog', LinksUteisEnum.BLOG, true),
              new Link('Segurança e Prevenção', '/institucional/seguranca-e-prevencao-a-fraude'),
              new Link('Políticas de Privacidade', LinksUteisEnum.RIPOLITICAPRIVACIDADE, true),
              new Link('Código de Conduta', '/institucional/codigo-conduta'),
              new Link('Educação Financeira', '/institucional/educacao-financeira')
            ]
          }
        ]
      },
      {
        menus: [
          {
            Titulo: 'Crédito para Você', Item: [
              new Link('Crédito Consignado', '/credito-para-voce/credito-consignado'),
              new Link('Crédito em Conta', '/credito-para-voce/credito-em-conta'),
              new Link('Crédito Imobiliário', '/credito-imobiliario'),
              new Link('Cartão de Crédito', '/credito-para-voce/cartao-credito'),
              new Link('Financiamento de Veículos', '/credito-para-voce/veiculos')
            ]
          },
          {
            Titulo: 'Crédito para sua Empresa', Item: [
              new Link('Conta para sua Empresa', '/credito-para-sua-empresa/conta-empresa'),
              new Link('Crédito Digital', '/credito-para-sua-empresa/creditos'),
              new Link('Modalidades de Crédito', '/credito-para-sua-empresa/creditos/operacoes-credito'),
              new Link('Cartão de Crédito', '/credito-para-sua-empresa/cartao-corporate'),
              new Link('Serviços para Empresas', '/servicos/servicos-empresa'),
              new Link('Seguros para Empresas', '/servicos/seguros-empresa'),
              new Link('Debt Capital Markets', '/credito-para-sua-empresa/debt-capital-markets'),
            ]
          },
        ]
      },
      {
        menus: [
          {
            Titulo: 'Investimentos', Item: [
              new Link('Daycoval Investe', '/investimentos/daycoval-investe'),
              new Link('Simulador de Investimentos', 'investimentos/simulador-investimentos'),
              new Link('Renda Fixa', 'renda-fixa'),
              new Link('Fundos de Investimento', 'fundos'),
              new Link('Renda Variável', '/investimentos/renda-variavel'),
              new Link('Previdência', '/investimentos/previdencia'),
              new Link('Indique um Amigo', LinksUteisEnum.CAMPANHASDAYCOVAL, true),
              new Link('Plataforma Aberta', '/investimentos/plataforma-aberta'),
              new Link('Daycoval Asset Management', '/asset', true),
              new Link('Relatórios de Investimentos', '/investimentos/relatorios-investimentos'),
              new Link('Transferência de Custódia', '/investimentos/transferencia-custodia'),
              new Link('Serviços ao Mercado de Capitais', '/investimentos/mercado-capitais')
            ]
          }
        ]
      },
      {
        menus: [
          {
            Titulo: 'Câmbio', Item: [
              new Link('Quero Comprar', '/cambio/simulador'),
              new Link('Moeda em Espécie', '/cambio/moedas'),
              new Link('Cartão de Viagem', '/cambio/cartao-viagem'),
              new Link('Remessas Internacionais', '/cambio/remessas-internacionais'),
              new Link('Compra de Cheques', '/cambio/compra-cheques'),
              new Link('DayPay', '/cambio/daypay'),
              new Link('MoneyGram', '/cambio/moneygram'),
              new Link('Cartão de Viagem Corporativo', '/cambio/cartao-viagem-corporativo'),
              new Link('Seguro Viagem', LinksUteisEnum.SEGUROVIAGEM),
              new Link('Encontre uma loja', LojasHelper.obterRotaPorRotaLojaEnum(RotaLojasEnum.CAMBIO)),
              new Link('Outros Serviços', '/cambio/outros-servicos'),
              new Link('Área Restrita para Agentes', LinksUteisEnum.AREARESTRITACAMBIO)
            ]
          },
          {
            Titulo: 'Tesouraria', Item: [
              new Link('Derivativos', '/tesouraria/derivativos'),
            ]
          },
        ]
      },
      {
        menus: [
          {
            Titulo: 'Serviços', Item: [
              new Link('Internet Banking - Dayconnect', '/servicos/internet-banking'),
              new Link('Cadastro Positivo', '/servicos/cadastro-positivo'),
              new Link('Daypag - Despachantes', '/servicos/daypag'),
              new Link('Serviços para Empresas', '/servicos/servicos-empresa'),
              new Link('Seguros para Empresas', '/servicos/seguros-empresa'),
              new Link('Cotação do Dólar Cartão de Crédito', '/servicos/cotacao-dolar'),
              new Link('Cheque Legal', '/servicos/cheque-legal'),
              new Link('Tarifas', '/servicos/tarifas'),
              new Link('Canal do Correspondente', 'https://www.daycoval.com.br/canalcorrespondente/login', true),
            ]
          },
          {
            Titulo: 'Atendimento', Item: [
              new Link('Dayane - Assistente Virtual', DayaneAssuntoRedirectHelper.ObterAtendimento(), true),
              new Link('Registrar uma Solicitação', '/atendimento/registrar-nova-solicitacao?q=1'),
              new Link('Acompanhe Solicitação', '/atendimento/acompanhe-seu-atendimento'),
              new Link('Canais de Atendimento', 'atendimento'),
              new Link('Perguntas Frequentes', '/perguntas-frequentes')
            ]
          }
        ]
      }
    ];
  }
}
