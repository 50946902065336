<section class="footer-contatos">
  <div class="container">
    <div class="row py-5 py-sm-0">
      <div class="col-12 col-md-5 mb-4 mb-sm-0" id="info-juridica">
        <img src="assets/img/logo/logo-daycoval-dark.png" alt="Banco Daycoval">
        <div class="descricao">
          <p>Banco Daycoval</p>
          <p>CNPJ 62.232.889/0001-90</p>
          <p>Todos os direitos reservados</p>
        </div>
      </div>
      <div class="col-12 col-md-3 mb-5 mb-sm-0" id="info-contatos">
        <div>
          <p>WhatsApp</p>
        </div>
        <div>
          <strong>Converse com a Dayane</strong>
        </div>
        <div class="d-flex justify-content-start mt-2" id="whatsapp">
          <fa-icon [icon]="faWhatsapp"></fa-icon>
          <a [href]="dayaneWhatsappRedirect" target="_blank">{{DAYANEWHATSAPP}}</a>
        </div>

        <div class="d-flex justify-content-left" id="midias-sociais">
          <a [href]="linkFacebook" target="_blank">
            <fa-icon [icon]="faFacebookF"></fa-icon>
          </a>
          <a [href]="linkYoutube" target="_blank">
            <fa-icon [icon]="faYoutube"></fa-icon>
          </a>
          <a [href]="linkInstagram" target="_blank">
            <fa-icon [icon]="faInstagram"></fa-icon>
          </a>
          <a [href]="linkLinkedin" target="_blank">
            <fa-icon [icon]="faLinkedinIn"></fa-icon>
          </a>
        </div>
      </div>
      <div class="col-12 col-md-4" id="info-app">
        <p>Já é nosso cliente ou deseja abrir uma conta?</p>
        <strong>BAIXE O APP "DAYCOVAL"</strong>
        <div class="d-flex flex-row flex-wrap justify-content-center
          justify-content-md-start mt-1 mb-4 mb-md-0">
          <ng-container *ngFor="let item of appStore">
            <a [href]="item?.Link" target="_blank" class="mr-2 mr-md-4
              d-md-block">
              <img class="" [src]="item?.UrlImagemDesktop" [alt]="item?.Titulo">
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>